<template>
  <div>
    <!-- 使用 Element Plus 的按钮组件 -->
    <el-button class="line-login-button" type="primary" @click="lineLogin">
      <img src="~/assets/img/btn_login_base.png" alt="Login with Line" />
    </el-button>

    <!-- 您的其他表单元素 -->
    <el-form class="login-form" label-position="top">
      <!-- 表单内容 -->
    </el-form>
  </div>
</template>

<script setup>
import { useRuntimeConfig } from 'nuxt/app';
import { useRouter, useRoute } from 'vue-router';

const config = useRuntimeConfig();
const router = useRouter();
const route = useRoute();

const state = ref('');

const lineLogin = () => {
  const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${config.public.lineClientId}&redirect_uri=${encodeURIComponent(config.public.lineCallbackUri)}&state=${state.value}&scope=profile%20openid%20email`;
  window.location.href = lineAuthUrl;
};

function generateUniqueState() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

onMounted(() => {
  state.value = generateUniqueState();
});
</script>


<style>
.line-login-button {
  background: none;
  border: none;
  padding: 0;
}

.line-login-button img {
  max-height: 40px;
  /* 调整图像大小 */
}
</style>
