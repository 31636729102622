<!--login.vue-->
<template>
  <div class="container">
    <LineLoginButton />
  </div>


  
</template>

<script setup>

definePageMeta({
  layout: 'none' // 指定不使用任何布局
}) 

const username = ref('');
const password = ref('');
const route = useRoute();
const router = useRouter();

</script>
  
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  width: 250px;
  /* 更小的宽度 */
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* 添加一些阴影效果 */
  padding: 20px;
  border-radius: 10px;
  /* 圆角边框 */
}
</style>
